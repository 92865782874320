import React from 'react';
// components
import Root from './routes';

const App = () => {
  return (
    <Root />
  );
};

export default App;
